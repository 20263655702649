import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="notfound-container">
      <Header />
      <div className="notfound-body">
        <div className="notfound-content">
          <h1 className="notfound-title">404</h1>
          <p className="notfound-message">Sorry, the page you are looking for cannot be found.</p>
          <Link to="/" className="notfound-button">Go back to Home</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
