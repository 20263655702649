// src/pages/Player.js
import React from 'react';

const Player = () => {
  return (
    <div
      style={{
        width: '100%',
        height: 'calc(var(--vh, 1vh) * 100)',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
      }}
    >
      <iframe
        src="/imaginear/player.html"
        title="AR Player"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          display: 'block',
          margin: 0,
          padding: 0,
        }}
      />
    </div>
  );
};

export default Player;