import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation(); // 현재 경로를 가져오기 위해 사용

  return (
    <nav style={styles.nav}>
      <ul style={styles.ul}>
        <li style={styles.li}>
          <Link 
            to="/" 
            style={location.pathname === "/" ? { ...styles.link, ...styles.activeLink } : styles.link}
          >
            Home
          </Link>
        </li>
        <li style={styles.li}>
          <Link 
            to="/support" 
            style={location.pathname === "/support" ? { ...styles.link, ...styles.activeLink } : styles.link}
          >
            Support
          </Link>
        </li>
      </ul>
    </nav>
  );
};

const styles = {
  nav: {
    backgroundColor: '#222',
    padding: '20px 0',  // 상하 패딩
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'sticky',  // 스크롤할 때 상단 고정
    top: 0,
    zIndex: 1000,  // 다른 요소보다 위에 오도록 설정
  },
  ul: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',  // 중앙 정렬
    alignItems: 'center', // 수직 중앙 정렬
    width: '100%', // ul이 전체 너비를 차지하도록 설정
  },
  li: {
    margin: '0 15px', // 좌우 마진을 설정하여 링크 간격을 줄임
    textAlign: 'center', // 텍스트 중앙 정렬
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    padding: '10px 0', // 수직 패딩을 유지하여 링크가 클릭 가능하도록 설정
    transition: 'color 0.3s ease',  // 부드러운 색상 전환
  },
  activeLink: {
    color: '#ff9900',  // 활성화된 링크 색상
    borderBottom: '2px solid #ff9900',  // 밑줄을 이용해 활성화 표시
  },
  '@media (max-width: 768px)': {
    link: {
      fontSize: '1rem',
    },
  },
};

export default Header;