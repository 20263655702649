import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <p style={styles.text}>&copy; 2024 Holoceive Inc. All rights reserved.</p>
      {/* <div style={styles.links}>
        <a href="https://twitter.com/holoceive" style={styles.link} target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a href="https://linkedin.com/company/holoceive" style={styles.link} target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
        <a href="https://github.com/holoceive" style={styles.link} target="_blank" rel="noopener noreferrer">
          GitHub
        </a>
      </div> */}
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#222',
    color: '#ddd',
    textAlign: 'center',
    padding: '20px 0',
    marginTop: '30px',
    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    bottom: 0,
    width: '100%',
  },
  text: {
    margin: 0,
    fontSize: '1rem',
  },
  links: {
    marginTop: '10px',
  },
  link: {
    color: '#ddd',
    margin: '0 15px',
    textDecoration: 'none',
    fontSize: '1rem',
    transition: 'color 0.3s ease',
  },
  linkHover: {
    color: '#fff',  // 마우스 오버 시 색상 변경
  },
  '@media (max-width: 768px)': { // 반응형 디자인 적용
    text: {
      fontSize: '0.875rem',
    },
    link: {
      fontSize: '0.875rem',
      margin: '0 10px',
    },
  },
  '@media (max-width: 480px)': {
    text: {
      fontSize: '0.75rem',
    },
    link: {
      fontSize: '0.75rem',
      margin: '0 8px',
    },
  },
};

export default Footer;