// src/pages/Privacy.js

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './privacy.css';

const Privacy = () => {
  return (
    <div className="privacy-container"> {/* 全体コンテナ */}
      <Header />
      <header className="privacy-header">
        <div className="privacy-overlay"></div> {/* オーバーレイ追加 */}
        <h1 className="privacy-header-text">Holoceive Player<br />プライバシーポリシー</h1>
      </header>
      <section className="privacy-section">
        {/* 目次 */}
        <h2>目次</h2>
        <ol className="privacy-text">
          <li><a href="#1-はじめに">はじめに</a></li>
          <li><a href="#2-個人情報の取り扱いについて">個人情報の取り扱いについて</a></li>
          <li><a href="#3-個人情報の収集を行わない理由">個人情報の収集を行わない理由</a></li>
          <li><a href="#4-クッキーおよび追跡技術の使用">クッキーおよび追跡技術の使用</a></li>
          <li><a href="#5-データの安全管理">データの安全管理</a></li>
          <li><a href="#6-ユーザーの権利">ユーザーの権利</a></li>
          <li><a href="#7-プライバシーポリシーの変更">プライバシーポリシーの変更</a></li>
          <li><a href="#8-お問い合わせ">お問い合わせ</a></li>
          <li><a href="#9-未成年者の個人情報保護">未成年者の個人情報保護</a></li>
          <li><a href="#10-第三者サービスとの連携">第三者サービスとの連携</a></li>
          <li><a href="#11-情報の保存および廃棄">情報の保存および廃棄</a></li>
          <li><a href="#12-情報主体と法定代理人の権利・義務">情報主体と法定代理人の権利・義務</a></li>
          <li><a href="#13-自動情報収集デバイスの設置、運用、および拒否に関する事項">自動情報収集デバイスの設置、運用、および拒否に関する事項</a></li>
          <li><a href="#14-行動情報の収集、利用、および拒否に関する事項">行動情報の収集、利用、および拒否に関する事項</a></li>
          <li><a href="#15-個人情報保護責任者に関する事項">個人情報保護責任者に関する事項</a></li>
          <li><a href="#16-その他（通知）">その他（通知）</a></li>
        </ol>

        {/* 各セクション */}
        <h2 id="1-はじめに">1. はじめに</h2>
        <p className="privacy-text">
          ホロシーブ株式会社（Holoceive Inc.）（以下「当社」といいます）は、<strong>Holoceive Player</strong>（以下「本サービス」といいます）をご利用いただくお客様のプライバシーを尊重し、個人情報を一切収集いたしません。本プライバシーポリシーは、当社が個人情報を収集しないことを明確にし、ユーザーの皆様に安心して本サービスをご利用いただくための指針を示すものです。
        </p>

        <h2 id="2-個人情報の取り扱いについて">2. 個人情報の取り扱いについて</h2>
        <p className="privacy-text">
          当社は、本サービスの提供にあたり、ユーザーから個人情報を収集しません。また、クッキーやその他の追跡技術を使用してユーザーの行動を追跡することもありません。したがって、ユーザーの個人情報の収集、利用、共有に関する懸念は生じません。
        </p>

        <h2 id="3-個人情報の収集を行わない理由">3. 個人情報の収集を行わない理由</h2>
        <p className="privacy-text">
          当社は、ユーザーのプライバシーを最優先に考え、本サービスの運営において個人情報を必要としない設計を採用しています。これにより、ユーザーのプライバシー保護を強化し、安心して本サービスをご利用いただける環境を提供しています。
        </p>

        <h2 id="4-クッキーおよび追跡技術の使用">4. クッキーおよび追跡技術の使用</h2>
        <p className="privacy-text">
          当社は、本サービスの提供にあたり、クッキーや類似の追跡技術を使用しません。ユーザーのデバイスに情報を保存することもなく、ユーザーの行動を追跡することもありません。
        </p>

        <h2 id="5-データの安全管理">5. データの安全管理</h2>
        <p className="privacy-text">
          当社は、個人情報を一切収集しないため、データの安全管理に関する具体的な対策は必要ありません。ただし、サービスの運営において必要な技術的および組織的な対策は講じており、サービスの品質と信頼性を維持するために努めています。
        </p>

        <h2 id="6-ユーザーの権利">6. ユーザーの権利</h2>
        <p className="privacy-text">
          本サービスにおいて個人情報を収集していないため、ユーザーは個人情報に関する権利（開示請求、訂正、削除、利用停止の請求など）を行使する必要はありません。
        </p>

        <h2 id="7-プライバシーポリシーの変更">7. プライバシーポリシーの変更</h2>
        <p className="privacy-text">
          当社は、法令の変更やサービス内容の変更に伴い、本プライバシーポリシーを改定することがあります。改定後のポリシーは、本サービス上に掲載された時点から効力を持ちます。重要な変更がある場合は、ユーザーに対して適切な方法で通知いたします。
        </p>

        <h2 id="8-お問い合わせ">8. お問い合わせ</h2>
        <p className="privacy-text">
          本プライバシーポリシーに関するお問い合わせは、以下の連絡先までお願いいたします。
        </p>
        <ul className="privacy-text">
          <li><strong>メールアドレス</strong>: bioh@holoceive.com</li>
        </ul>

        <h2 id="9-未成年者の個人情報保護">9. 未成年者の個人情報保護</h2>
        <p className="privacy-text">
          当社は、未成年者（14歳未満）の個人情報を一切収集しません。万が一、未成年者から個人情報が提供された場合、速やかに削除いたします。未成年者による個人情報の提供が疑われる場合は、下記の連絡先までご連絡ください。
        </p>
        <ul className="privacy-text">
          <li><strong>メールアドレス</strong>: bioh@holoceive.com</li>
        </ul>

        <h2 id="10-第三者サービスとの連携">10. 第三者サービスとの連携</h2>
        <p className="privacy-text">
          当社は、本サービスの提供において第三者サービスとの連携を行っていません。したがって、第三者サービスを通じた個人情報の収集、利用、共有も行っておりません。
        </p>

        <h2 id="11-情報の保存および廃棄">11. 情報の保存および廃棄</h2>
        <p className="privacy-text">
          当社は、個人情報を一切収集しないため、情報の保存および廃棄に関する具体的な手続きはありません。ただし、サービスの運営において必要な情報については、適切な方法で保存および管理しています。
        </p>

        <h2 id="12-情報主体と法定代理人の権利・義務">12. 情報主体と法定代理人の権利・義務</h2>
        <p className="privacy-text">
          本サービスにおいて個人情報を収集していないため、情報主体およびその法定代理人の権利・義務に関する事項は発生しません。
        </p>

        <h2 id="13-自動情報収集デバイスの設置、運用、および拒否に関する事項">13. 自動情報収集デバイスの設置、運用、および拒否に関する事項</h2>
        <p className="privacy-text">
          当社は、本サービスにおいて自動情報収集デバイス（クッキーなど）を設置、運用しておらず、ユーザーの情報を保存することもありません。したがって、クッキーの設置、運用および拒否に関する設定は不要です。
        </p>

        <h2 id="14-行動情報の収集、利用、および拒否に関する事項">14. 行動情報の収集、利用、および拒否に関する事項</h2>
        <p className="privacy-text">
          当社は、本サービスの提供においてユーザーの行動情報を収集、利用しません。従って、行動情報の収集、利用、および拒否に関する事項は適用されません。
        </p>

        <h2 id="15-個人情報保護責任者に関する事項">15. 個人情報保護責任者に関する事項</h2>
        <p className="privacy-text">
          当社は、個人情報を一切収集しないため、個人情報保護責任者の設置は行っておりません。
        </p>

        <h2 id="16-その他（通知）">16. その他（通知）</h2>
        <p className="privacy-text">
          当社は、関連法規や内部ポリシーの変更に従い、本プライバシーポリシーを修正することがあります。その際、変更内容を本サービス上で適切に通知いたします。
        </p>

        {/* フッター部分の追加要素 */}
        <div className="privacy-footer-links">
          <a href="/privacy" className="privacy-text">個人情報処理方針</a>
          <span className="privacy-text">2024 Holoceive Inc.</span>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Privacy;