import React, { useEffect, useState } from 'react';
import './appclip.css';

const AppClipSample = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isIOS17OrAbove, setIsIOS17OrAbove] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [showRetryMessage, setShowRetryMessage] = useState(false);

  useEffect(() => {
    // iOS 버전 감지 함수
    const getIOSVersion = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // iOS인지 확인
      const isIOS =
        (/iPad|iPhone|iPod/.test(userAgent) ||
          (userAgent.includes("Macintosh") && 'ontouchend' in document)) &&
        !window.MSStream;

      if (isIOS) {
        // iOS 버전 추출
        let match = userAgent.match(/OS (\d+)_?(\d+)?_?(\d+)?/);
        if (!match) {
          match = userAgent.match(/Version\/(\d+)\.(\d+)?/); // iPadOS용 대체 탐지
        }

        if (match) {
          const majorVersion = parseInt(match[1], 10);
          const minorVersion = parseInt(match[2] || '0', 10);

          // iOS 17.0 이상인지 확인
          if (majorVersion > 17 || (majorVersion === 17 && minorVersion >= 0)) {
            setIsIOS17OrAbove(true);
            return;
          }
        }
      }
      // iOS 17 미만이거나 iOS가 아님을 알림
      setErrorMessage('iOS 17 以上でのみ使用できます。');
    };

    // Safari 브라우저 감지 함수 (웹뷰 제외)
    const detectSafari = () => {
      const userAgent = navigator.userAgent;
      const vendor = navigator.vendor;

      // Apple에서 제공하는 브라우저인지 확인하고, Chrome, Firefox, Opera, Edge 등 다른 iOS 브라우저는 제외
      const isSafariBrowser =
        vendor && vendor.includes('Apple') &&
        /Safari/.test(userAgent) &&
        !/CriOS|FxiOS|OPiOS|EdgiOS/.test(userAgent) &&
        // 웹뷰를 제외하기 위한 추가 조건
        !window.webkit?.messageHandlers &&
        !(window.navigator?.standalone);

      setIsSafari(isSafariBrowser);
    };

    getIOSVersion();
    detectSafari();
  }, []);

  const handleAppClipLaunch = () => {
    if (isIOS17OrAbove && !isSafari) {
      // App Clip 실행 여부를 감지하기 위한 visibilitychange 이벤트 핸들러
      const handleVisibilityChange = () => {
        if (document.hidden) {
          // App Clip이 실행됨
          clearTimeout(timeoutId);
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
      };

      // visibilitychange 이벤트 리스너 추가
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // App Clip을 호출하는 링크로 이동
      window.location.href = 'https://appclip.apple.com/id?p=com.holoceive.HoloceivePlayer.Clip';

      // 1.5초 후 App Clip이 실행되지 않았을 경우 안내 문구 표시
      const timeoutId = setTimeout(() => {
        if (!document.hidden) {
          setShowRetryMessage(true);
        }
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }, 1500);
    }
  };

  return (
    <div className="appclip-container">
      <main>
        <section className="appclip-section">
          <p className="appclip-text">
            Sample for ANIMATE
          </p>
          {isIOS17OrAbove ? (
            isSafari ? (
              <div className="safari-instruction">
                <p>バナーをクリックしてください。</p>
              </div>
            ) : (
              <div>
                <button className="appclip-button" onClick={handleAppClipLaunch}>
                  Launch AR
                </button>
                {showRetryMessage && (
                  <p className="retry-message" style={{ color: 'black', marginTop: '10px' }}>
                    アプリクリップが起動しなかった場合は、もう一度クリックしてください。
                  </p>
                )}
              </div>
            )
          ) : (
            <p className="error-message" style={{ color: 'black' }}>{errorMessage}</p>
          )}
        </section>
      </main>
    </div>
  );
};

export default AppClipSample;
