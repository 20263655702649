import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './home.css';

const Home = () => {
  return (
    <div className="home-container">
      <Header />
      <main>
        <header className="home-header">
          <div className="home-overlay"></div>
          <h1 className="home-header-text">Welcome to Holoceive Inc.</h1>
        </header>
        <section className="home-section">
          <h2>About Us</h2>
          <p className="home-text">
            At Holoceive Inc., we strive to bring immersive augmented reality experiences to everyone.
            Our cutting-edge technology blends the digital and physical worlds, allowing users to explore,
            interact, and create in entirely new ways.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;