import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Support from './pages/Support';
import Player from './pages/Player';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';
import AppClipSample from './pages/AppClipSample';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/player" element={<Player />} />
        <Route path="/player/privacy" element={<Privacy />} />
        <Route path="/clip" element={<AppClipSample />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
