import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './support.css'; // support 전용 CSS 파일 불러오기

const Support = () => {
  return (
    <div>
      <Header />
      <header className="support-header">
        <div className="support-overlay"></div>
        <h1 className="support-header-text">Contact Holoceive Support</h1>
      </header>
      <section className="support-section">
        <h2>We're here to help</h2>
        <form action="https://formspree.io/f/xdkooqdn" method="POST" className="support-form">
          <label htmlFor="email" className="support-label">Your Email:</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" required className="support-input"/>

          <label htmlFor="message" className="support-label">Your Message:</label>
          <textarea id="message" name="message" rows="6" placeholder="How can we assist you?" required className="support-textarea"></textarea>

          <input type="submit" value="Send Message" className="support-submit"/>
        </form>
      </section>
      <Footer />
    </div>
  );
};

export default Support;